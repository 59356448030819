import React, { useState } from 'react';
import './Presentation.css';

const Presentation: React.FC = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const images = [
        'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/fi3e8wdhteikunp2yu2n.jpg',
        'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/f35ezncreufbzo5qif3v.jpg',
        'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/xuuy39xlhfrcdifx84ei.jpg',
    ];

    const handleImageClick = (image: string) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="presentation-container">
            <h1 className="title">À propos de SOGESTRA SA</h1>

            <div className="content">
                <p>
                    <strong>SOGESTRA SA</strong> est une société mixte de bâtiment, travaux publics et services née de la volonté et de l'ambition
                    d'être la référence qualité-prix en RDC. Elle intervient dans divers secteurs de la construction :
                </p>

                <ul className="list">
                    <li>Conception, construction et réhabilitation de bâtiments</li>
                    <li>Étude, réhabilitation, maintenance et modernisation de routes et ouvrages d’art</li>
                    <li>Études, construction et réhabilitation de structures hydrauliques et d'assainissement</li>
                    <li>Production de matériaux de construction</li>
                </ul>

                <p>En plus de cela, SOGESTRA propose plusieurs services :</p>
                <ul className="list">
                    <li>Location de main-d'œuvre spécialisée et non spécialisée</li>
                    <li>Location d'engins de travaux publics et de petit matériel de construction</li>
                    <li>Services logistiques de transport</li>
                    <li>Expertise immobilière</li>
                </ul>

                <h2>Historique</h2>
                <p>
                    Fondée en octobre 2013, SOGESTRA SA a harmonisé ses textes avec le droit OHADA en mars 2015.
                    Aujourd’hui, l’entreprise emploie 43 agents permanents et gère plusieurs succursales à Kinshasa, Goma, Beni et Bunia.
                </p>

                <h2>Structure de l’entreprise</h2>
                <p>
                    Chaque bureau est autonome et dirigé par un chef, soutenu par une administration complète. L’équipe technique
                    est menée par un ingénieur principal supervisant plusieurs ingénieurs civils et contremaîtres.
                </p>

                <h2>Adresse et Contact</h2>
                <ul className="list">
                    <li><strong>Kinshasa</strong>: 171, Nguma, Q. Jolie Parc, Commune de Ngaliema</li>
                    <li><strong>Goma</strong>: 1, Bâtiment Kamanzi, Q. Des volcans (Rond-point Chukudu)</li>
                    <li><strong>Beni</strong>: 46, Boulevard Nyamuisi, Q. Résidentiel, Bungulu</li>
                    <li><strong>Bunia</strong>: 36, Boulevard Lumumba, Commune Mbunya</li>
                </ul>

                <table className="contact-table">
                    <tbody>
                        <tr>
                            <th>RCCM</th>
                            <td>CD/GOM/RCCM/15-B-0311</td>
                        </tr>
                        <tr>
                            <th>ID. Nat</th>
                            <td>5-490-N95681C</td>
                        </tr>
                        <tr>
                            <th>NIF</th>
                            <td>A1509188E</td>
                        </tr>
                        <tr>
                            <th>Contact</th>
                            <td>
                                <a href="tel:+243999082041">+243 999 082 041</a> |
                                <a href="mailto:sogestra@sogestracongo.com">sogestra@sogestracongo.com</a> |
                                <a href="mailto:grmugisha@gmail.com">grmugisha@gmail.com</a>
                            </td>
                        </tr>
                        <tr>
                            <th>Point focal</th>
                            <td>Grace Mugisha</td>
                        </tr>
                    </tbody>
                </table>

                <h2>Nos principaux clients</h2>
                <p>Nous travaillons avec l'État congolais, les agences des Nations Unies, des ONG internationales et locales, ainsi que des sociétés minières.</p>

                <div className="image-gallery">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Projet ${index + 1}`}
                            className="gallery-image"
                            onClick={() => handleImageClick(image)}
                        />
                    ))}
                </div>

                {selectedImage && (
                    <div className="modal" onClick={closeModal}>
                        <img src={selectedImage} alt="Zoomed" className="modal-image" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Presentation;
