import React, { useState } from 'react';
import './Galerie.css';
import 'react-image-lightbox/style.css';
import ImageLightbox from 'react-image-lightbox';

const images = [
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/et99vlhpll3scp13xwmd.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/jorioioo7wneceomjopa.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/fy4rhe8mrzktlinilyfd.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/sngijmnckduh8t08moce.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/ckmgtlzuauikstxkl8fz.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/fi3e8wdhteikunp2yu2n.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/monrvr2suncujmrl2zpn.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/f35ezncreufbzo5qif3v.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/rqiid3qme1fxofknujyw.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/arraxhrmzrzk7jfizhbu.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/a08juqmwh9xysv1mrtyp.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/xuuy39xlhfrcdifx84ei.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/us1ndstdu2qqpqrtnzcg.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/pw9e5vsy5tlmkvdlt5vw.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/gkduefviskcujnnnsp4y.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/ipav3u5wp3xp9cy4mfgh.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933714/sogestra/mmbfawb9ljdpz0qn0x0l.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/ltjjn6ir6lkmchlnrkd2.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/c6rnxqkq9lgpwl58wgsk.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/xhcwjlyop9bgacpzrbeh.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/enk8udez3ls14ttoedbl.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/wqpn1nsd8vi8mil0bt6m.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/mxvfd6zqxx2tu2fr41i6.jpg',    
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/bzo4amekds0uzh2drz9c.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/eeadseklbvtnmfafucy6.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/fshpjxv3tyhqxujomvgf.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/ynbkzbddbtghj4mbhsze.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/fqehjqbde69zmhzrvsk7.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/bsb7kzo2r8b5aqd2p2l8.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933712/sogestra/gij8yaoylvummvl1vgyr.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/ccjhq2bs8hmjue0jwb2f.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/uxhk3vfo2z0uzrehwufl.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/hdktqcaxsjq3obr0ityz.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/dceifwnglgi69knhbfbf.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/vuw1wvsuruw4vw7zqqaf.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/m4k25b6fwkbqryhvwxfr.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933711/sogestra/athgjpgei4cxw4pnu2nq.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933710/sogestra/ia60oalujv1obfjme6kh.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933710/sogestra/j2rwqnh2keycmn7qxq5z.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933710/sogestra/hmxkndzsu8cteijqujwc.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/xebh7hyvyjvoxcwiqncf.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933710/sogestra/yyg5ffa4mdrf4mssrmmr.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933710/sogestra/rjrn05ym4vrfn4fhhvm3.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/mbw733eyo6kuvhvqv5pp.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/jgnl0u04z7p3lgciw9vk.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/ym4zeuvthedzqugvugsl.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/heldmgvapjc3manenael.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/lpqaormlehh3usiwswou.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/rgfpjrfygcrgg5gvnzkq.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/qbeb8a9gj0gdwfrmcz0z.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/b0keum7vr6n6xtaszqbg.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/nv7tupdovqsjcpbyoa3m.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/whqvewdknniuovrc4utt.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933707/sogestra/tzg8uw4mkjcmi0eco6kq.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933707/sogestra/mitpv1jgs5aoj2wn7oa1.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933707/sogestra/kfneresdh8p3lqsnnojn.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933707/sogestra/yeylkhwbxerdahaxsdln.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933706/sogestra/q41xsedvgq8svmgazime.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933706/sogestra/jlgmv1r9dshmsavwhuzi.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933706/sogestra/xowolozev9whwvw0cuax.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933706/sogestra/ybjplojtqs7zaleix08q.jpg',
    'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933706/sogestra/z3klfyjtwjy2vg3swfdg.jpg',
];

const Galerie: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 12;
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(images.length / imagesPerPage);

    return (
        <div className="galerie-container">
            <h1>Galerie</h1>
            <div className="image-grid">
                {currentImages.map((image, index) => (
                    <div key={index} className="image-item">
                        <img 
                            src={image} 
                            alt={`Galerie ${index + 1}`} 
                            onClick={() => {
                                setPhotoIndex(index);
                                setIsOpen(true);
                            }} 
                        />
                    </div>
                ))}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {isOpen && (
                <ImageLightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </div>
    );
};

export default Galerie;
