import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaBars, FaTimes } from 'react-icons/fa';

const Navbar: React.FC = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleMobileMenu = () => setMobileMenuOpen((prev) => !prev);

  return (
    <div className="navbar-container">
      <div className="top-header">
        <span><a href="tel:+243999082041">📞 +243 999 082 041</a></span>
        <span><a href="mailto:sogestra@sogestracongo.com">✉️ sogestra@sogestracongo.com</a></span>
        <span>📍 001 port, des volcans, Goma</span>
        <div className="social-icons">
          <a href="#"><FaFacebook /></a>
          <a href="#"><FaTwitter /></a>
          <a href="#"><FaInstagram /></a>
          <a href="#"><FaYoutube /></a>
        </div>
      </div>

      <nav className="navbar">
        <div className="logo">
          SO<span className="logo-accent">GES</span>TRA <span className="logo-accent">Sa</span>
        </div>

        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <li><a href="/Accueil">Accueil</a></li>
          <li
            className="dropdown"
            ref={dropdownRef}
            onClick={() => setDropdownOpen((prev) => !prev)}
          >
            <a href="#" onClick={(e) => e.preventDefault()}>À propos ▼</a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li><a href="/Presentation">Présentation</a></li>
                <li><a href="/Realisation">Réalisation</a></li>
              </ul>
            )}
          </li>
          <li><a href="/projects">Projets</a></li>
          <li><a href="/Galerie">Galerie</a></li>
          <li><a className="portail" href="#">Portail</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
