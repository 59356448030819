import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import 'animate.css';

const typingSpeed = 100;
const erasingSpeed = 50;
const pauseBeforeErase = 2000;
const pauseBeforeNextSlide = 1000;
const startTypingDelay = 500;


const Home: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [showCursor, setShowCursor] = useState(false);
  const charIndex = useRef(0);
  const isErasing = useRef(false);
  const sectionsRef = useRef<HTMLDivElement[]>([]);

  const slides = [
    {
      image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728931609/sogestra/dkomostmnezc19vx15ot.jpg',
      text: 'Bienvenue à la SOGESTRA',
      subText: 'Votre partenaire en transport et logistique.',
    },
    {
      image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728931625/sogestra/s39iu3g15ndhuxnvpy84.jpg',
      text: 'Suivez nos projets innovants',
      subText: 'Innovation au service de votre mobilité.',
    },
    {
      image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728931587/sogestra/tvwgfjy3lusf1oql3qdr.jpg',
      text: "Nous construisons l'avenir",
      subText: 'Rejoignez-nous pour façonner demain.',
    },
  ];
  const services = [
    {
      name: "Conception, construction et réhabilitation de bâtiments",
    },
    {
      name: "Etude, exécution des travaux de réhabilitation, maintenance et de modernisation des routes et des ouvrages d’art (ponts, dalots,…)",
    },
    {
      name: "Etudes, construction et réhabilitation de structures et travaux hydrauliques et d'assainissement",
    },
    {
      name: "Production de matériaux de construction",
    },
    {
      name: "Location de main-d'œuvre spécialisée et non spécialisée",
    },
    {
      name: "Location des engins de travaux publics",
    },
    {
      name: "Location de petit matériel de construction",
    },
    {
      name: "Un service logistique de transport",
    },
    {
      name: "Un service d'expertise immobilière",
    },
  ];
  const clients = [
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013366/sogestra/gvnj84oqpb865rkrzb1x.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013366/sogestra/grgcl631xusnvfm3xmhb.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/m8dmugehhqjczzwppjqv.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/npyw9bfq5gwfcttxsuac.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/vzegwxvlxlnqvipxob3c.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/rmxisdobj2dr81cyok3i.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/b6fq8vmn6lr0dgzwgagf.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/m4ecjoqki2yjnnx5x1z6.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/oupyk3vkj5wtrvssroly.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/idcvee62eei4hurphq27.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/qnwjmqlxymucftnkdbjy.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013365/sogestra/pmvyj2eabh9qvoqjn5au.png' },
  ];
  const clients2 = [
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013364/sogestra/kixognt7al0bkwrly8ou.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013364/sogestra/rnnq1dh2quwkbg51hvgj.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013364/sogestra/v3rgwfjfrcuzh8ojkz6g.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013364/sogestra/wvchicimj9xb4ry6iqoe.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013363/sogestra/efb0prjf9km9tyl2xj2t.jpg' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013363/sogestra/cevsfdjh5d5bzmy97uz2.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013363/sogestra/ntew2wol52yoyiszgb9n.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013362/sogestra/qpuvned3ovqa1nmj6mlp.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013362/sogestra/tpnkm0yjvneuarpp4sii.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013363/sogestra/p6vgxsapa93grwt0bepb.png' },
    { image: 'https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729013362/sogestra/g4vb3midrigwtinfahcn.jpg' },

  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % clients.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [clients.length]);

  const visibleClients = [...clients, ...clients, ...clients];
  const visibleClients2 = [...clients2, ...clients2, ...clients2];

  useEffect(() => {
    const text = slides[currentSlide].text;

    const handleTyping = () => {
      if (!isErasing.current && charIndex.current < text.length) {
        setTypedText((prev) => prev + text[charIndex.current]);
        charIndex.current++;
        setShowCursor(true);
        setTimeout(handleTyping, typingSpeed);
      } else if (!isErasing.current && charIndex.current === text.length) {
        setShowCursor(false);
        setTimeout(() => {
          isErasing.current = true;
          handleTyping();
        }, pauseBeforeErase);
      } else if (isErasing.current && charIndex.current > 0) {
        setTypedText((prev) => prev.slice(0, -1));
        charIndex.current--;
        setShowCursor(true);
        setTimeout(handleTyping, erasingSpeed);
      } else if (isErasing.current && charIndex.current === 0) {
        isErasing.current = false;
        setShowCursor(false);
        setTimeout(() => {
          setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, pauseBeforeNextSlide);
      }
    };

    const startTypingTimeout = setTimeout(handleTyping, startTypingDelay);

    return () => clearTimeout(startTypingTimeout);
  }, [currentSlide]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, [typedText]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate__animated', 'animate__fadeInUp');
          }
        });
      },
      { threshold: 0.3 }
    );

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => {
      sectionsRef.current.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="home-page">
      <div className="slider-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover' }}
          >
            <div className="slide-content">
              <h1>
                {typedText}
                {showCursor && <span className="cursor">|</span>}
              </h1>
            </div>
            <div className="slide-content-h2">
              <h2 className="top-text">{slide.subText}</h2>
              <button className="bottom-text">Explorez plus avec nous.</button>
            </div>
          </div>
        ))}
        <div className="slider-dots">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="ceo-container">
        <div className="header">
          <h1>Message du PDG de l'entreprise</h1>
        </div>

        <div className="ceo-info-wrapper">
          <div>
          <img
            src="https://res.cloudinary.com/dcm4wxnm6/image/upload/v1729158497/sogestra/hkm5jqdhq9fjmakgpvfa.png"
            alt="grace mugisha"
            className="ceo-photo"
          />
          <div className="message">
            <h2>Ir. Grace MUGISHA</h2>
          </div>
          </div>
          <div className="ceo-info-box">
            <div className="message">
              <div className='message-fl'>
                <blockquote>
                  Bienvenue sur le site de SOGESTRA !
                </blockquote>
                <p className='message-p'>Spécialiste du BTP au Congo, SOGESTRA s’appuie sur une expertise éprouvée et une équipe jeune, dynamique et expérimentée, capable de relever les défis les plus complexes dans le secteur de la construction. Notre entreprise se distingue par son engagement à fournir des solutions de haute qualité, adaptées aux besoins spécifiques de nos clients institutionnels et professionnels.
                  <br /> <br />Nous sommes fiers de collaborer avec des partenaires prestigieux tels que les agences des Nations Unies, les ONG internationales, les structures étatiques, les sociétés minières et industrielles, ainsi que les ONG nationales et entreprises privées. Grâce à notre rigueur et à notre professionnalisme, nous contribuons activement au développement des infrastructures publiques et professionnelles, avec un focus sur des réalisations durables et innovantes.
                  <br /><br />
                  Chez SOGESTRA, nous sommes votre partenaire de confiance pour transformer vos projets en réussites tangibles.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="signature">
          <p>Ir. Grace MUGISHA</p>
          <p className="ceo-title">Président-Directeur Général</p>
        </div>
      </div>
      <div className="about-container" ref={(el) => el && sectionsRef.current.push(el)}>
        <div className="about-left">
          <h5>A propos de l’entreprise</h5>
          <h1>Société Générale<br />des services<br />et travaux</h1>
          <div className="underline"></div>

        </div>
        <div className="about-right">
          <h1>
            SOGESTRA s.a est une société mixte de bâtiment et travaux publics et services née de la volonté et de l'ambition d'être la référence qualité-prix dans son secteur en RDC.
          </h1>
          <p>
            SOGESTRA exerce ses activités sur l'ensemble du territoire de la RDC. En plus de ses bureaux permanents à Kinshasa, Goma, Beni et Bunia, SOGESTRA déploie des sites temporaires liés au projet en cours d'exécution.
            Kinshasa: 171, Nguma, Q. Jolie Parc, Commune de Ngaliema, Kinshasa
            Goma: 1, Bâtiment Kamanzi, Q. Des volcans. Réf. Rond-point Chukudu
            Beni: 46, Boulevard Nyamuisi, Q. Résidentiel, Bungulu
            Bunia: 36, Boulevard Lumumba, Commune Mbunya.

          </p>
        </div>
      </div>
      <div className="services-container">
        <h2>CE QUE NOUS VOUS PROPOSONS:</h2>
        <table className="services-table">
          <thead>
            <tr>
              <th>Nos Services</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <tr key={index}>
                <td>{service.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <div className='carousel'>
          <h1>nos principaux clients : </h1>
          <div className="carousel-container">
            <div
              className="carousel-track"
              style={{
                transform: `translateX(-${(currentIndex % clients.length) * 100}%)`,
              }}
            >
              {visibleClients.map((client, index) => (
                <div className="carousel-item" key={index}>
                  <img src={client.image} alt={`Client ${index + 1}`} />
                </div>
              ))}
            </div>
            <div
              className="carousel-track"
              style={{
                transform: `translateX(-${(currentIndex % clients.length) * 100}%)`,
              }}
            >
              {visibleClients2.map((client, index) => (
                <div className="carousel-item" key={index}>
                  <img src={client.image} alt={`Client ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
