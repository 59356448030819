import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-section">
          <div className="footer-title-h1">
          SO<span className="logo-accent">GES</span>TRA <span className="logo-accent">Sa</span>
          <div className="underline"></div>
          </div>
          <h2 className="footer-title">Adresse</h2>

          <p className='footer-title-p'>
          📍Kinshasa: 171, Nguma, Q. Jolie Parc, Commune de Ngaliema, Kinshasa
            <br />📍Goma: 1, Bâtiment Kamanzi, Q. Des volcans. Réf. Rond-point Chukudu
            <br />📍Beni: 46, Boulevard Nyamuisi, Q. Résidentiel, Bungulu
            <br />📍Bunia: 36, Boulevard Lumumba, Commune Mbunya.
          </p>
        </div>

        <div className="footer-section">
          <h2 className="footer-title">Liens Rapides</h2>
          <ul className="footer-links">
            <li><a href="#">F.A.Q</a></li>
            <li><a href="#">Politique de Cookies</a></li>
            <li><a href="#">Conditions d'utilisation</a></li>
            <li><a href="#">Support</a></li>
            <li><a href="#">Carrières</a></li>
          </ul>
          <div className="contact-info">
            <i className="fas fa-envelope"></i>
            <span><a href="mailto:sogestra@sogestracongo.com">sogestra@sogestracongo.com</a></span>
          </div>
          <div className="contact-info">
            <i className="fas fa-phone-alt"></i>
            <span><a href="tel:+243999082041">+243 999 082 041</a></span>
          </div>
        </div>

        <div className="footer-section">
          <h2 className="footer-title">Abonnez-vous à la Newsletter</h2>
          <form className="subscribe-form">
            <input
              type="email"
              placeholder="Votre email ici"
              required
            />
            <button type="submit">S'abonner</button>
          </form>
          <div className="social-icons">
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-facebook"></i></a>
          </div>
        </div>
        <div className="footer-section">
          <h2 className="footer-title">Tags</h2>
          <div className="footer-tags">
            <button className="footer-tag">Objects</button>
            <button className="footer-tag">Blog</button>
            <button className="footer-tag">Business</button>
            <button className="footer-tag">Interiors</button>
            <button className="footer-tag">News</button>
            <button className="footer-tag">Post</button>
            <button className="footer-tag">Projects</button>
            <button className="footer-tag">Design</button>
            <button className="footer-tag">Construction</button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Sogestracongo | Tous droits réservés</p>
      </div>
    </footer>
  );
};

export default Footer;
