import React from 'react';

const Projects: React.FC = () => {
  return (
    <div>
      <h2>Nos Projets</h2>
      <p>Cette page est actuellement en construction. Merci de votre compréhension.</p>
    </div>
  );
}

export default Projects;
