import React, { useState } from 'react';
import './Realisation.css';

interface Réalisation {
    id: number;
    title: string;
    description: string;
    images: string[];
}

const réalisations: Réalisation[] = [
    {
        id: 1,
        title: "Construction du marché de MULO",
        description: "Construction du marché de MULO",
        images: [
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/jorioioo7wneceomjopa.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/fy4rhe8mrzktlinilyfd.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/fi3e8wdhteikunp2yu2n.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/monrvr2suncujmrl2zpn.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/f35ezncreufbzo5qif3v.jpg",
        ],
    },
    {
        id: 2,
        title: "Formation professionnelle",
        description: "Ex combattant ...",
        images: [
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933716/sogestra/et99vlhpll3scp13xwmd.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/arraxhrmzrzk7jfizhbu.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933708/sogestra/rgfpjrfygcrgg5gvnzkq.jpg",
        ],
    },
    {
        id: 3,
        title: "Ouverture de la route SAYO",
        description: "Ouverture de la route SAYO",
        images: [
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/sngijmnckduh8t08moce.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933715/sogestra/rqiid3qme1fxofknujyw.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933713/sogestra/ltjjn6ir6lkmchlnrkd2.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728931598/sogestra/ch64rddzo2y2y5wlfzj8.jpg",
            "https://res.cloudinary.com/dcm4wxnm6/image/upload/v1728933709/sogestra/heldmgvapjc3manenael.jpg",
        ],
    },
];

const ITEMS_PER_PAGE = 12;

const Realisation: React.FC = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const displayedRéalisations = réalisations.slice(startIndex, endIndex);

    const handleImageClick = (image: string) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="realisation-container">
            <h1 className="title">Nos Réalisations</h1>
            <div className="content">
                {displayedRéalisations.map((realisation) => (
                    <div key={realisation.id} className="realisation-group">
                        <h2>{realisation.title}</h2>
                        <p>{realisation.description}</p>
                        <div className="image-gallery">
                            {realisation.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`${realisation.title} ${index + 1}`}
                                    className="gallery-image"
                                    onClick={() => handleImageClick(image)}
                                />
                            ))}
                        </div>
                    </div>
                ))}

                {selectedImage && (
                    <div className="modal" onClick={closeModal}>
                        <img src={selectedImage} alt="Zoomed" className="modal-image" />
                    </div>
                )}
            </div>

            <div className="pagination">
                {Array.from({ length: Math.ceil(réalisations.length / ITEMS_PER_PAGE) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index)}
                        className={`page-button ${currentPage === index ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Realisation;
