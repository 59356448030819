import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.tsx';
import Footer from './components/Footer.tsx';
import Home from './pages/Home.tsx';
import Realisation from './pages/Realisation.tsx';
import Projets from './pages/Projects.tsx';
import Presentation from './pages/Presentation.tsx'
import Galerie from './pages/Galerie.tsx'
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Accueil" element={<Home />} />
            <Route path="/Presentation" element={<Presentation />} />
            <Route path="/Realisation" element={<Realisation />} />
            <Route path="/projects" element={<Projets />} />
            <Route path="/Galerie" element={<Galerie />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
